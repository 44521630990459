﻿/**
 *  @ngdoc controller
 *  @name Customer Communication Notes Directive
 *  @description This directive is used in order details page for RX DX AX
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('custcommunicationModal', function () {
        return {
            controller: 'custCommunicationController',
            restrict: 'E',
            scope: {
                modelId: "@",
                customer: '=',
                ctrl: '=',
            },
            templateUrl: 'app/directive/custCommunication/custCommunicationDir.html'
        }
    }).controller('custCommunicationController', function ($scope, toaster, customerResource, $localStorage) {


        if ($scope.customer.custNotes == undefined || $scope.customer.custNotes == null) {
            $scope.customer.custNotes = {};
            $scope.customer.custNotes.urls = [];
        }

        $scope.ctrl.custNotes = {};
        $scope.ctrl.custNotes.urls = [];

        $scope.ctrl.addMore = function (id) {
            if (id == 1) {
                $scope.ctrl.isMoreNotes = true;
            } else
                $scope.ctrl.isMoreNotes = false;
        }

        $scope.ctrl.uploadNotes = function (event) {
            var files = event.target.files;
            $scope.ctrl.uploadCustNotesImage(files);
            event.target.value = ''; // Reset the file input after files are selected
        };


        /**
        * @name uploadImageForLensNotes
        * @param {any}  value value as object,
        * @description upload Image For Order Notes
        * @author Ajay Vyas
        * @returns {void}
        */
        $scope.ctrl.uploadCustNotesImage = function (value) {
            var imageExtn;
            var filesSelected = value;

            var fileCount = ($scope.customer.custNotes.urls == undefined || !$scope.customer.custNotes.urls.length) ? 0 : $scope.customer.custNotes.urls.length;
            if (!$scope.customer.custNotes) {
                $scope.customer.custNotes = {};
                $scope.customer.custNotes.urls = [];
            }
            if (fileCount > 3) {
                toaster.error("Can't upload more than 3 files.");
                _.defer(function () {
                    $scope.$apply();
                });
                return false;
            }

            if (filesSelected.length > 0) {
                angular.forEach(filesSelected, function (value, key) {
                    var fileToLoad = value;

                    // Check if the file name already exists
                    var fileNameExists = $scope.customer.custNotes.urls.some(function (file) {
                        return file.imageName === fileToLoad.name;
                    });

                    if (fileNameExists) {
                        toaster.error("File name already exists.");
                        _.defer(function () {
                            $scope.$apply();
                        });
                        return;
                    }

                    var fileReader = new FileReader();
                    fileReader.onload = function (fileLoadedEvent) {
                        var newdata = fileLoadedEvent.target.result;
                        imageExtn = fileToLoad.name.substr(fileToLoad.name.lastIndexOf('.') + 1);
                        if (imageExtn.toLowerCase() == "png" || imageExtn.toLowerCase() == "jpg" || imageExtn.toLowerCase() == "jpeg" || imageExtn.toLowerCase() == "pdf") {
                            var FileSize = fileToLoad.size / 1024 / 1024; // in MB
                            if (FileSize > 2) {
                                toaster.error("File size should not exceed 2 MB");
                                document.getElementById("imageNotes").value = "";
                                _.defer(function () {
                                    $scope.$apply();
                                });
                                return;
                            }

                            if ($scope.customer.custNotes.urls !== undefined && $scope.customer.custNotes.urls.length >= 3) {
                                toaster.error("Can't upload more than 3 files.");
                            }
                            else {
                                var urls = {};
                                urls.imageName = fileToLoad.name;
                                // if file/image name is too long then display short name by appending .... at the end of name//
                                var fileNamewithExt = fileToLoad.name;
                                var lastIndex = fileNamewithExt.lastIndexOf('.');
                                var name = fileNamewithExt.substring(0, lastIndex);
                                urls.showUrl = name + '.' + imageExtn;//name.length < 18 ? fileToLoad.name : ((name.substring(0, 18)) + "..." + imageExtn);
                                //end
                                urls.imageData = newdata;
                                urls.imageExtn = imageExtn;
                                urls.url = fileLoadedEvent.target.result;
                                urls.urlId = 0;
                                urls.enableDelete = true;

                                $scope.lensNoteUrls = $scope.ctrl.custNotes.urls !== undefined ? $scope.ctrl.custNotes.urls : [];
                                if (!$scope.customer.custNotes && !$scope.customer.custNotes.urls) {
                                    $scope.customer.custNotes.urls = [];
                                }
                                $scope.lensNoteUrls.push(urls);
                                $scope.customer.custNotes.urls.push(urls);
                                //$scope.ctrl.custNotes.urls = $scope.orderDet.custNotes.urls;//$scope.lensNoteUrls;
                            }
                        }
                        else {
                            toaster.error("Please select png, jpg, jpeg, pdf formats only.");
                        }
                        _.defer(function () {
                            $scope.$apply();
                        });
                    };
                    fileReader.readAsDataURL(fileToLoad);
                });
            }
        }


        //$scope.ctrl.savecustNotes = function () {
        //    $scope.ctrl.custNotes.urls = $scope.ctrl.custNotes.urls.filter(x => x.isDeleted !== true);
        //    $scope.ctrl.custNotes.urls.forEach(x => x.isSaved = true);
        //    $scope.ctrl.custNotes.note = $scope.ctrl.custNotes.notes;
        //}

        $scope.ctrl.savecustNotes = function () {

            $scope.ctrl.custNotes.practiceId = $scope.ctrl.customer.practiceId;
            $scope.ctrl.custNotes.urls = $scope.customer.custNotes.urls;
            $scope.ctrl.custNotes.processedZone = (new Date()).getTimezoneOffset();
            $scope.ctrl.custNotes.scleralLensType = parseInt($scope.ctrl.custNotes.scleralLensType);
            customerResource.saveCustCommunicationNotes($scope.ctrl.custNotes, function (data) {
                // Logic after successful save
                if ($scope.ctrl.custNotes.scleralLensType ||
                    ($scope.ctrl.custNotes.notes && $scope.ctrl.custNotes.notes.trim().length > 0) ||
                    ($scope.ctrl.custNotes.urls && $scope.ctrl.custNotes.urls.length > 0)) {
                    // At least one field is filled, enable save button
                    $scope.ctrl.customer.isCCNotesAdded = true;
                } else {
                    // No relevant field is filled, set isCCNotesAdded to false
                    $scope.ctrl.customer.isCCNotesAdded = false;
                }

                $scope.ctrl.custNotes = {};
                $scope.ctrl.isMoreNotes = false;

                var key = ($scope.ctrl.customer.isCCNotesAdded) ? 'true' : 'false';
                var dataToSend = { key: key };
                $scope.$emit('ccNotesUpdated', dataToSend);
                toaster.success("CC Notes Updated Successfully.");
            });

        }


        $scope.ctrl.getCustCommunicationNotes = (orderId) => {
            customerResource.getCustCommunicationNotes({ practiceId: $scope.ctrl.customer.practiceId }, function (data) {
                $scope.ctrl.customer.custNotes = data.ccNotes;
                // $scope.ctrl.custNotes.scleralLensType = data.ccNotes != null ? data.ccNotes.notes[0]?.scleralLensType : null;
                if (data.ccNotes != null && data.ccNotes.scleralLensType != null) {
                    // Set the selected radio button based on scleralLensType from the backend
                    $scope.ctrl.custNotes.scleralLensType = data.ccNotes.scleralLensType.toString();
                }

            });
        }

        $scope.ctrl.deleteImageUrl = function (index) {
            document.getElementById("imageNotes").value = "";
            if ($scope.ctrl.customer.custNotes.urls.length > 0) {
                $scope.ctrl.customer.custNotes.urls.splice(index, 1);
            }
        }


        $scope.displayActiveUrls = function (item) {
            return item.isDeleted != true;
        };

        $scope.ctrl.cancelCustNotes = () => {
            $scope.ctrl.custNotes = {
                notes: $scope.ctrl.custNotes.note,

            };

            $scope.ctrl.custNotes.urls = $scope.ctrl.custNotes.urls != undefined ? $scope.ctrl.custNotes.urls.length > 0 ? $scope.ctrl.custNotes.urls.filter(x => x.isSaved !== false) : [] : [];
            $scope.ctrl.custNotes.urls.forEach(x => x.isDeleted = false);
            $scope.ctrl.customer.custNotes = null;
            $scope.ctrl.isMoreNotes = false;
            // Reset the file input field
            document.getElementById('imageNotes').value = '';
        }

        $scope.ctrl.openFile = function (i) {
            if (i.enableDelete == false) {
                window.open(i.url);
            }
            else {
                if (i.imageExtn.toLowerCase() !== "pdf") {
                    var image = new Image();
                    image.src = i.url;
                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else {
                    var win = window.open();
                    win.document.write('<iframe src="' + i.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                }
            }
        }
    });

}(window.angular));
